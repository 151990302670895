(function () {
  'use strict';

  frappe.ui.form.ContactAddressQuickEntryForm.prototype.get_variant_fields = (function () {
    let cached = frappe.ui.form.ContactAddressQuickEntryForm.prototype.get_variant_fields; // keep cr_wms override

    return function () {
      return [
        ...cached.apply(this, arguments),
        {
          fieldtype: "Section Break",
          label: __("Subscriptions"),
          collapsible: 1
        },
        {
          label: __("Is Subscriber"),
          fieldname: "is_subscriber",
          fieldtype: "Check",
        },
        {
          label: __("Item"),
          fieldname: "initial_subscription_item",
          depends_on: "eval:doc.is_subscriber",
          options: "Subscription Item",
          fieldtype: "Link",
        },
        {
          label: __("Count"),
          fieldname: "initial_subscription_amount",
          depends_on: "eval:doc.is_subscriber",
          fieldtype: "Float",
        },
        {
          fieldtype: "Column Break"
        },
        {
          options: "<div style=\"height: 37px\"></div>",
          fieldtype: "HTML",
        },
        {
          label: __("Subscription Language"),
          fieldname: "initial_subscription_language",
          depends_on: "eval:doc.is_subscriber",
          fieldtype: "Link",
          options: "Language",
        },
        {
          label: __("Start Date"),
          fieldname: "initial_subscription_start",
          depends_on: "eval:doc.is_subscriber",
          fieldtype: "Date",
        }
      ];
    }
  })();
})();
